
.digital-welcome {
    padding-bottom: 124px;
}

.digital-welcome .sec-left { 
    margin: auto 0;
    padding-bottom: 20px;
}

.sec-left .button-field {
    display: inline-block;
    vertical-align: middle;
    min-width: 184px;
}

.sec-left .button-field button {
    width: 100%;
}

@media (max-width: 1199px){
    .digital-welcome {
        padding-bottom: 10px;
    }
}
@media (max-width: 991px){
    .sec-left .button-field {
        min-width: 145px;
        margin-top: 5px;
    }

    .digital-welcome .sec-left {
        padding-bottom: 0;
    }

    .digital-welcome {
        padding-bottom: 38px;
    }
}
@media (max-width: 767px){
    .digital-welcome .sec-left {
        margin-top: 10px;
    }

    .sec-left .button-field {
        margin-top: -8px;
        min-width: 115px;
    }


    .digital-welcome {
        padding-bottom: 20px;
    }
}