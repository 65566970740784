
.two-block-sec {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 0 15px 0;
}

.block-column {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
    border-radius: 5px;
    width: 48.7%;
    margin-right: 2.60%;
    margin-bottom: 40px;
}

.block-column:nth-child(2n) {
    margin-right: 0;
}

.column-head h3 {
    margin: 0;
}

.column-head {
    padding: 29px 30px 27px;
    border-bottom: 3px solid #F5F7FA;
}
.column-body .custom-radio {
    width: 100%;
    margin: 0 0 31px;
}

.column-body {
    padding: 28px 30px;
}
.column-body-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.column-body .custom-radio input[type="radio"] + label {
    font-weight: 400;
    padding-left: 50px;
    line-height: 30px;
    display: inline-block;
    vertical-align: middle;
}

.column-body-col {
    width: 47%;
    padding-right: 15px;
}

.button-field.next-step-btn {
    min-width: 277px;
}

.button-field.next-step-btn input {
    font-size: 18px;
    line-height: 20px;
}
.column-body img {
    margin-top: 9px;
    display: block;
}


@media (max-width: 1500px) {
    .column-body-col {
        width: 50%;
    }
    
    .column-body .custom-radio input[type="radio"] + label {
        padding-left: 40px;
    }
    
    .column-body {
        padding: 28px 25px;
    }
}

@media (max-width: 1350px) {
    .column-body-col {padding-right: 0;}

    .column-body {
        padding: 28px 20px;
    }

    .column-body .custom-radio input[type="radio"] + label {
        font-size: 15px;
        padding-left: 36px;
    }
}
@media (max-width: 1199px) {
    .block-column {
        width: 100%;
        margin: 0 0 30px;
    }
    
    .two-block-sec {padding-top: 5px;}
}
@media (max-width: 991px) {
    .two-block-sec {
        padding-top: 8px;
    }
    
    .column-head {
        padding: 29px 20px 27px;
    }
    
    .column-head h3 {
        text-transform: capitalize;
    }
    
    .column-body {
        padding: 30px 40px 34px 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    
    .column-body .custom-radio input[type="radio"] + label {
        padding-left: 50px;
        font-size: 16px;
    }
    
    .column-body .custom-radio {
        margin: 0 0 31px;
    }

    .column-body-row {
        width: calc(100% - 220px);
    }
    
    .column-body-img {
        width: 220px;
        padding: 0 0 0;
    }
    
    .column-body img {
        margin: 0;
    }
    
    .column-body-col {
        width: 100%;
        margin-bottom: 31px;
    }
    
    .column-body .custom-radio:last-child {
        margin-bottom: 0;
    }
    
    .column-body-col:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 767px) {
    .column-body-row {
        width: 100%;
        margin-bottom: 19px;
    }
    
    .two-block-sec {
        padding: 0;
    }
    
    .column-head {
        padding: 20px 20px 19px;
    }
    
    .column-body {
        padding: 18px 40px 20px 20px;
    }
    
    .column-body .custom-radio input[type="radio"] + label {
        padding-left: 30px;
        font-size: 14px;
        line-height: 21px;
    }
    
    .column-body .custom-radio {
        margin: 0 0 19px;
    }
    
    .column-body-img {
        width: 146px;
    }
    
    .block-column {
        margin-bottom: 15px;
    }
    
    .column-body-col {
        margin-bottom: 19px;
    }

    .block-column + .button-field.next-step-btn {
        margin-top: 4px;
    }
}