.main_tab_content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background: #fff;
	padding: 40px 40px 5px;
	box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}
.content-box {
	background: #fff;
	width: 31.6%;
	margin-right: 2.6%;
	border: 1px solid #ebebeb;
	position: relative;
	margin-bottom: 35px;
}
.content-box:after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	box-shadow: 0px 8px 99px rgba(0, 0, 0, 0.06);
	transition: all 0.5s ease-in-out;
	opacity: 0;
}
.content-box:hover:after,
.content-box:focus:after {
	opacity: 1;
}
.content-box:nth-child(3n) {
	margin-right: 0;
}
.ecp_resource .tab-listing ul li {
    padding-right: 0px;
}
.box-image {
	background-repeat: no-repeat;
	border-bottom: 1px solid #ebebeb;
	background-size: cover;
	background-position: center center;
	padding: 22% 15px 21.4%;
	position: relative;
	z-index: 1;
}
.box-image:after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0.7;
	background: #0a0a0a;
	transition: all 0.5s ease-in-out;
	opacity: 0;
	z-index: -1;
}

.button-hover {
	opacity: 0;
}
.content-box:hover .box-image:after,
.content-box:focus .box-image:after {
	opacity: 0.7;
}
.content-box:hover .button-hover,
.content-box:focus .button-hover {
	opacity: 1;
}
.box-content-info p {
	color: rgba(100, 100, 100, 0.6);
	font-family: Roboto, sans-serif;
	margin-bottom: 16px;
}
.box-content-info h4 {
	margin: 0 0 10px;
}

.box-content-info {
	padding: 20px 20px 22px;
}

.box-content-info p:last-child {
	margin-bottom: 0;
}
.box-image .button-field {
	display: table;
	margin: 0 auto 30px;
}
.box-image .button-field:last-child {
	margin-bottom: 0;
}
.popup-wrap {
	padding: 50px;
	background: #fff;
	margin: 100px 0;
	position: relative;
	max-width: 1120px;
}
.MuiBackdrop-root {
	background: rgba(0, 0, 0, 0.9) !important;
}
.gray-close-ic {
	display: inline-block;
	vertical-align: middle;
	position: absolute;
	top: 10px;
	right: 10px;
	max-width: 30px;
}
@media (max-width: 1500px) {
	.ecp_resource .tab-listing ul li {
		padding-right: 12px;
		font-size: 18px;
	}

	.ecp_resource .tab-listing ul li:last-child {
		padding-right: 0;
	}

	.main_tab_content {
		padding: 30px 30px 0;
	}

	.box-image .button-field {
		margin-bottom: 20px;
	}

	.box-image {
		padding: 15% 15px;
	}

	.box-content-info {
		padding: 20px 15px;
	}
	.popup-wrap {
		margin: 50px 0;
		max-width: 900px;
	}
}

@media (max-width: 1199px) {
	.content-box,
	.content-box:nth-child(3n) {
		width: 48%;
		margin-right: 4%;
		margin-bottom: 30px;
	}

	.content-box:nth-child(2n) {
		margin-right: 0;
	}
	.popup-wrap {
		margin: 100px 0;
		max-width: 650px;
		padding: 40px;
	}

	.gray-close-ic {
		max-width: 20px;
	}
}

@media (max-width: 991px) {
	.box-image {
		padding: 21.5% 15px;
	}

	.box-content-info h4 {
		font-size: 18px;
		margin-bottom: 8px;
	}

	.box-content-info {
		padding: 18px 19px;
	}

	.box-content-info p {
		margin-bottom: 18px;
	}

	.content-box {
		margin-bottom: 20px;
	}

	.content-box,
	.content-box:nth-child(3n) {
		margin-bottom: 20px;
	}

	.main_tab_content {
		padding: 30px 30px 10px;
	}
	.ecp_resource .tab-listing ul li {
		font-size: 24px;
		padding-right: 39px;
	}

	.ecp_resource .tab-listing ul li a {
		padding-right: 2px;
	}
}
@media (max-width: 767px) {
	.popup-wrap {
		margin: 100px 0;
		max-width: 650px;
		padding: 40px;
	}
	.gray-close-ic {
		top: 5px;
		right: 5px;
		max-width: 17px;
	}
	.popup-wrap {
		width: calc(100% - 30px);
		max-width: 100%;
		margin: 20px auto;
		padding: 25px 15px;
	}
	.content-box,
	.content-box:nth-child(3n) {
		width: 100%;
		margin: 0 0 15px;
	}
	.content-box:last-child {
		margin-bottom: 0;
	}
	.main_tab_content {
		padding: 20px 15px;
	}
	.box-content-info p {
		font-size: 12px;
		margin-bottom: 15px;
	}
	.box-content-info h4 {
		font-size: 14px;
	}
	.ecp_resource .tab-listing ul li {
		font-size: 16px;
		padding-right: 30px;
	}
	.tab-arrow:after {
		background-size: 6px;
		background-position: center right 10px;
		width: 30px;
		top: -12px;
	}

	.box-image {
		padding: 23.5% 15px;
	}

	.box-content-info {
		padding: 13px 14px;
	}
}
@media (max-width: 479px) {
	.gray-close-ic {
		top: 6px;
		right: 6px;
		max-width: 10px;
	}
}
