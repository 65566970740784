.left-sidebar { 
    background: rgb(10,92,127);
    background: -moz-linear-gradient(180deg, rgba(10,92,127,1) 0%, rgba(21,136,186,1) 100%); 
    background: -webkit-linear-gradient(180deg, rgba(10,92,127,1) 0%, rgba(21,136,186,1) 100%); 
    background: linear-gradient(180deg, rgba(10,92,127,1) 0%, rgba(21,136,186,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a5c7f",endColorstr="#1588ba",GradientType=1);
    width: 360px;
    padding: 80px 0 0;
    transition: all 0.5s ease-in-out;
    position: fixed;
    left: 0;
    top: 100px;
    height: calc(100vh - 102px);
    overflow-y: auto;
    z-index: 11;
    left: -360px;
}
.left-sidebar.active {
    left: 0px;
}
.left-sidebar .MuiExpansionPanelSummary-content span {
    display: block;
    width: 100%;
}

.left-sidebar nav {
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    overflow: hidden;
}

.left-sidebar .MuiExpansionPanelDetails-root a {
    padding-left: 50px;
    border: none;
}
.left-sidebar ul {margin: 0;}
.left-sidebar li { font-family: 'Roboto Condensed', sans-serif; color: rgba(255, 255, 255, 0.60); font-weight: 400; font-size: 18px; }
.left-sidebar li a,.left-sidebar .MuiExpansionPanelSummary-content span { display: block; width: 100%; line-height: 1;padding: 22px 30px 21px;border-bottom: 1px solid rgba(255, 255, 255, 0.10); transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;}
/* .left-sidebar li:first-child a {border-top: 1px solid rgba(255, 255, 255, 0.7);} */
.left-sidebar li a:hover,.left-sidebar li a:focus,.left-sidebar .MuiExpansionPanelSummary-content span:hover,.left-sidebar .MuiExpansionPanelSummary-content span:focus {background: #01AFB0;color: #fff;}
.left-sidebar li a img { display: inline-block; vertical-align: middle; margin-right: 26px;opacity: 0.7; transition: all 0.5s ease-in-out; }
.left-sidebar li a:hover img,.left-sidebar li a:focus img {opacity: 1;}
.left-sidebar  .MuiPaper-root.MuiExpansionPanel-root {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    box-shadow: none;
}

.left-sidebar .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 1px;
    padding: 0;
}

.left-sidebar .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0;
    padding: 0;
}

.left-sidebar .MuiExpansionPanelDetails-root {
    padding: 0;
    flex-wrap: wrap;
    background: rgba(10, 92, 127, 0.5);
}

.left-sidebar .MuiExpansionPanelSummary-content {
    margin: 0;
}

.left-sidebar .MuiExpansionPanelSummary-root {
    min-height: 1px;
    padding: 0;
}
.left-sidebar .MuiExpansionPanelSummary-content span {
    position: relative;
}

.left-sidebar .MuiExpansionPanelSummary-content span:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 19px;
    background: url('../../../assets/images/down-arrow.svg');
    width: 14px;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.6;
    transform: rotate(90deg);
    transition: all0.5s ease-in-out;
}
.left-sidebar .MuiExpansionPanelSummary-root.Mui-expanded .MuiExpansionPanelSummary-content span:before {
    transform: rotate(0deg);
}
.left-sidebar .MuiExpansionPanelSummary-content span:hover:before ,.left-sidebar .MuiExpansionPanelSummary-content span:focus:before {
    opacity: 1;
}

@media (max-width: 1500px) {
    .left-sidebar {
        width: 320px;
    }
    
}

@media (max-width: 1366px) {
    .left-sidebar {
		height: calc(100vh - 90px);
        top: 90px;
        padding: 50px 0 0;
    }
    .left-sidebar li a, .left-sidebar .MuiExpansionPanelSummary-content span {
        padding: 21px 25px 20px;
    }
    
    .left-sidebar .MuiExpansionPanelDetails-root a {
        padding-left: 45px;
    }
    .left-sidebar .MuiExpansionPanelSummary-content span {
        padding-right: 40px;
    }
}

@media (max-width: 1199px) {
    .left-sidebar { width: 300px;  top: 90px;  height: calc(100vh - 90px);}
    .left-sidebar li { font-size: 16px; }
    .left-sidebar li a { padding: 16px 30px; }    
    .left-sidebar li a img { margin-right: 20px; max-width: 20px; margin-top: -3px; }
}
@media (max-width: 991px) {
    .left-sidebar { padding: 40px 0 0;top: 80px; left: -300px; bottom: 0; min-height: calc(100vh - 80px); transition: all 0.5s ease-in-out; left: -300px;}
    .left-sidebar.active { left: 0; }
    .left-sidebar li a { padding: 17px 30px 16px; }
    .left-sidebar li a img { margin-right: 16px; }
    .left-sidebar li a, .left-sidebar .MuiExpansionPanelSummary-content span {
        padding: 17px 21px 16px;
    }
    
    
    .left-sidebar .MuiExpansionPanelDetails-root a {
        padding-left: 41px;
    }
    
    .left-sidebar .MuiExpansionPanelSummary-content span {
        padding-right: 30px;
    }
    
    .left-sidebar .MuiExpansionPanelSummary-content span:before {
        right: 14px;
    }
}
@media (max-width: 767px) {
    .left-sidebar {
        top: 50px;
        height: calc(100vh - 50px);
        width: 250px;
        padding: 30px 0 0;
        left: -250px;
    }
    .left-sidebar li a, .left-sidebar .MuiExpansionPanelSummary-content span {
        font-size: 14px;
        padding: 13px 15px 13px;
    }
    
    .left-sidebar .MuiExpansionPanelDetails-root a {
        padding-left: 31px;
    }
    
    .left-sidebar .MuiExpansionPanelSummary-content span:before {
        width: 10px;
        background-size: 9px;
        right: 12px;
    }
    
    .left-sidebar .MuiExpansionPanelSummary-content span {
        padding-right: 25px;
    }
}