.site-header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	box-shadow: 0px 4px 6px rgba(10, 10, 10, 0.12);
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 11;
	background: #fff;
}

.header-left {
	width: 26%;
	padding: 29px 40px 22px;
}
.humburger-menu {
	display: inline-block;
	vertical-align: middle;
	margin-right: 20px;
	cursor: pointer;
	margin-top: -6px;
}
.humburger-menu span {
	display: block;
    background: #cccccc;
    width: 22px;
    height: 2px;
    margin-bottom: 5px;
}

.humburger-menu span:last-child {
    margin-bottom: 0;
}
.site-logo {
	display: inline-block;
	vertical-align: middle;
}
.site-logo a {
	display: inline-block;
	max-width: 205px;
}
.site-logo a img {
	display: block;
}

.header-right {
	width: 74%;
	justify-content: flex-end;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
}
.header-nav {
	padding-right: 30px;
}

.header-nav ul {
	margin: 0;
}

.header-nav ul li {
	list-style: none;
	display: inline-block;
	vertical-align: middle;
	padding-right: 60px;
}
.header-nav ul li:last-child {
	padding-right: 0;
}
.header-nav ul li a {
	display: inline-block;
	vertical-align: middle;
	font-size: 18px;
	font-weight: 700;
	color: #646464;
	font-family: "Roboto Condensed", sans-serif;
	text-transform: capitalize;
	position: relative;
	padding: 38px 0;
}

.header-nav ul li a:hover,
.header-nav ul li a:focus {
	color: #1073c6;
}

.header-nav ul li a:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 3px;
	background: #1073c6;
	transition: all 0.5s ease-in-out;
}

.header-nav ul li a:hover:after,
.header-nav ul li a:focus:after {
	width: 100%;
}
.header-nav ul li .icon-status {
	top: 28px;
	left: 19px;
}
.header-nav ul li a svg path {
	transition: all 0.5s ease-in-out;
}
.header-nav ul li a:hover svg path,
.header-nav ul li a:focus svg path {
	fill: #1073c6;
}

.header-nav ul li svg {
	display: inline-block;
	vertical-align: middle;
	margin-right: 11px;
}

.account-info {
	background: #f5f7fa;
	padding: 35px 35px 35px 40px;
}
.MuiButton-label {
	font-size: 18px;
	font-weight: 700;
	text-transform: none;
	padding: 0 23px 0 0;
	position: relative;
}
.MuiButton-label:after {
	content: "";
	position: absolute;
	top: 0;
	right: 5px;
	bottom: 0;
	background: url("../../../assets/images/dropdown-arrow.svg") no-repeat center
		center;
	width: 15px;
	background-size: 15px;
}
.MuiPaper-root.MuiPopover-paper {
	top: 72px !important;
	min-width: 270px;
	box-shadow: none;
	border-radius: 5px;
	padding: 40px;
	background: transparent;
	margin-left: 0;
	right: 0;
    left: auto !important;
}
.MuiButton-label {
	align-items: center;
}
.MuiButton-label img {
	margin-right: 8px;
}
.MuiButtonBase-root.MuiButton-text {
	padding: 0;
}
.MuiButton-root:hover,
.MuiButton-root:focus {
	background-color: transparent !important;
}
.arrow-popup {
	padding: 0;
	position: relative;
	background: #fff;
	box-shadow: 0px 10px 30px #0000001a;
}
.arrow-popup ul {
	margin: 0;
}
.arrow-popup li {
	list-style: none;
	font-size: 16px;
	color: #646464;
	font-weight: 400;
}
.arrow-popup li:last-child a {
	border: none;
}
.arrow-popup li a {
	display: block;
	line-height: 1;
	border-bottom: 1px solid #f5f7fa;
	padding: 21px 15px 20px;
}
.arrow-popup li a:hover,
.arrow-popup li a:focus {
	background: #f5f7fa;
	color: #646464;
}
.arrow-popup li a img,
.arrow-popup li a svg {
	margin-right: 15px;
	display: inline-block;
	vertical-align: middle;
}
.right-icons {
	padding: 4px 48px 4px 39px;
	border-left: 2px solid #dddddd;
}
.right-icons ul {
	margin: 0;
}
.right-icons ul li {
	list-style: none;
	display: inline-block;
	vertical-align: middle;
	padding-right: 52px;
}
.right-icons ul li:last-child {
	padding-right: 0;
}
.right-icons ul li a {
	display: inline-block;
	vertical-align: middle;
	position: relative;
}
.right-icons ul li a img {
	max-width: 25px;
	display: block;
}
.icon-status {
	display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    background: #177bc9;
    border-radius: 100%;
    position: absolute;
    top: -7px;
    right: -6px;
    color: #ffff;
    text-align: center;
    line-height: 13px;
    font-size: 8px;
    border: 1px solid #fff;
    font-weight: 500;
}

.arrow-popup.notification {
	padding: 0;
	min-width: 366px;
}
.arrow-popup.notification:after {
	left: auto;
	right: 10px;
}
.arrow-popup.notification h6 {
	font-size: 16px;
	color: #1073c6;
	font-weight: 700;
	font-family: "Roboto Condensed", sans-serif;
	margin: 0;
	border-bottom: 1px solid #f5f7fa;
	padding: 23px 16px 19px;
}
.arrow-popup.notification li {
	padding: 0 0px;
	margin-bottom: 0;
}
.arrow-popup.notification li a {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 20px 16px;
	border-bottom: 1px solid #f5f7fa;
}
.arrow-popup.notification li:last-child a {
	border-bottom: none;
}
.arrow-popup .profile-ul li a .a,
.arrow-popup.notification li a .b {
	transition: all 0.5s ease-in-out;
}
/* .arrow-popup .profile-ul li a:hover .a,
.arrow-popup li .profile-ul a:focus .a,
.arrow-popup.notification li a:hover .b,
.arrow-popup.notification li a:focus .b {
	fill: #1073c6;
} */
.left-img {
	width: 40px;
}
.arrow-popup li .left-img img {
	margin: 0;
	display: block;
}
.right-content {
	width: calc(100% - 40px);
	padding-left: 14px;
	position: relative;
}
.right-content p {
	margin: 0;
	font-family: "Roboto", sans-serif;
}
.right-content p strong {
	font-weight: 500;
}
.right-content p small {
	color: #cccccc;
	font-size: 14px;
	display: block;
	font-weight: 700;
	font-family: "Roboto Condensed", sans-serif;
}
.noti-time {
	display: inline-block;
	vertical-align: middle;
	font-size: 12px;
	font-weight: 700;
	color: #cccccc;
	font-family: "Roboto Condensed", sans-serif;
	position: absolute;
	top: 0;
	right: 0;
}

@media (max-width: 1500px) {
	.right-icons ul li {
		padding-right: 30px;
	}
	.right-icons ul li img {
		max-width: 28px;
		max-height: 23px;
	}

	.header-nav ul li {
		padding-right: 50px;
	}
}

@media (max-width: 1366px) {
	.header-left {
		padding: 25px 20px 20px 30px;
	}

	.header-nav ul li {
		padding-right: 40px;
	}

	.right-icons {
		padding: 4px 30px 4px;
	}

	.account-info {
		padding: 30px;
	}

	.header-nav ul li a {
		padding: 32px 0;
	}
	.MuiPaper-root.MuiPopover-paper {
		top: 62px !important;
		margin-left: 0px;
	}
}

@media (max-width: 1199px) {
	.site-logo a {
		max-width: 160px;
	}
	.MuiButton-label {
		font-size: 16px;
	}

	.arrow-popup li {
		font-size: 16px;
	}

	.arrow-popup li a img,
	.arrow-popup li a svg {
		margin-right: 11px;
	}

	.arrow-popup.notification {
		margin-left: 20px;
	}
	.arrow-popup.notification h6 {
		padding: 17px 16px 15px;
		font-size: 14px;
	}
	.arrow-popup.notification li a {
		padding: 15px;
	}
	.right-content p {
		font-size: 14px;
	}
	.header-nav ul li a {
		font-size: 16px;
	}

	.header-nav ul li svg {
		margin-right: 9px;
		max-width: 20px;
	}

	.header-nav ul li {
		padding-right: 22px;
	}

	.right-icons {
		padding: 1px 20px;
	}

	.account-info {
		padding: 30px 20px;
	}

	.header-nav {
		padding-right: 24px;
	}

	.header-left {
		padding: 28px 10px 20px 20px;
		width: 24%;
	}

	.header-right {
		width: 76%;
	}

	.header-nav ul li .icon-status {
		left: 15px;
	}
}
@media (max-width: 991px) {
	.right-icons ul li {
		padding-right: 30px;
	}

	.site-header .header-nav ul li {
		display: none;
	}
	.site-header .header-nav ul li:last-child {
		display: block;
	}
	.header-left {
		width: 30%;
		padding: 25px 10px 20px 20px;
	}

	.header-right {
		width: 70%;
	}

	.site-logo a {
		max-width: 155px;
	}

	.MuiButton-label {
		padding-right: 17px;
	}

	.MuiButton-label:after {
		background-size: 10px;
		background-position: center right;
	}

	.account-info {
		padding: 28px 15px 27px 21px;
	}

	.header-nav ul li a {
		padding: 26px 0;
	}

	.MuiButton-label img {
		max-width: 111px;
	}

	.right-icons {
		padding: 1px 28px 1px 19px;
	}

	.right-icons ul li a img {
		max-width: 30px;
	}

	.header-nav {
		padding-right: 22px;
	}

	.header-nav ul li svg {
		max-width: none;
		margin-right: 11px;
	}

	.header-nav ul li .icon-status {
		top: 20px;
		left: 16px;
	}
	.MuiPaper-root.MuiPopover-paper {
		top: 50px !important;
		right: -20px;
	}
	.icon-status {
		top: -6px;
		right: -6px;
	}
}

@media (max-width: 767px) {
	.site-header .header-nav ul li a {
		font-size: 0;
		padding: 12px 0;
	}

	.site-header .header-nav ul li a svg {
		margin-right: 0;
		max-width: 17px;
	}

	.right-icons {
		border-left: none;
		padding: 1px 16px 1px 0px;
	}

	.site-logo a {
		max-width: 103px;
	}

	.account-info {
		padding: 0;
		background: transparent;
	}

	.right-icons ul li a img {
		max-width: 18px;
		max-height: 15px;
	}

	.icon-status {
		width: 10px;
		height: 10px;
		font-size: 6px;
		font-weight: 500;
		line-height: 9px;
		top: -2px;
		left: 6px;
		padding: 0;
	}

	.MuiButton-label {
		width: 20px;
		height: 20px;
		border-radius: 100%;
		background: #f5f7fa;
		padding: 0;
		margin-right: 10px;
	}

	.MuiButton-label img {
		margin: 0;
	}

	.MuiButtonBase-root.MuiButton-text {
		min-width: 1px;
	}

	.MuiButton-label:after {
		right: -10px;
		background-size: 6px;
	}

	.header-left {
		padding: 15px 0px 10px 15px;
		width: 49%;
	}

	.header-right {
		padding-right: 15px;
		width: 51%;
	}

	.humburger-menu {
		margin-right: 16px;
	}

	.right-icons ul li {
		padding-right: 19px;
	}

	.header-nav {
		padding-right: 20px;
	}

	.header-nav ul li .icon-status {
		top: 15px;
		left: 10px;
	}
	.arrow-popup li {
		font-size: 14px;
	}

	.arrow-popup li a {
		padding: 16px 15px 14px;
	}

	.MuiPaper-root.MuiPopover-paper {
		min-width: 250px;
		top: 20px !important;
		right: -30px;
	}
	.arrow-popup.notification svg {
		max-width: 25px;
		margin-right: 5px;
	}
	
	.left-img {
		width: 30px;
	}
	
	.right-content {
		width: calc(100% - 30px);
		padding-left: 9px;
	}
	.arrow-popup.notification {
		min-width: 280px;
		margin-left: 80px;
	}
}

@media (max-width: 479px) {
	.arrow-popup.notification {min-width: 240px;margin-left: -30px;}

	.right-content {
		padding-right: 43px;
	}

	.arrow-popup.notification li a {
		padding: 13px 15px;
	}
}