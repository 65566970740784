.login_page{padding:50px;background-size:cover;background-position:center center;background-repeat:no-repeat; background-color: #fff;}
.login_page .main{min-height:calc(100vh - 100px);display:flex;flex-direction:row;flex-wrap:wrap;align-items:center;justify-content:center}
.inner-screen{display:flex;flex-direction:row;flex-wrap:wrap;background:#fff;box-shadow:25px 25px 99px rgba(10,10,10,.16);width:100%}

.left-column{width:50%;padding:80px}
.logo{margin-bottom:59px}
.logo a{display:inline-block;vertical-align:middle;max-width:258px}
.logo a img{display:block}.left-column h1{margin-bottom:40px}

.user-field{background-image:url(../../../assets/images/user-field.svg);background-repeat:no-repeat;background-position:center right 18px}
.pwd-field{background-image:url(../../../assets/images/lock-ic.svg);background-repeat:no-repeat;background-position:center right 18px}

.custom-checkbox{position:relative}.custom-checkbox input[type=checkbox]{position:absolute;top:0;left:-999999px}
.custom-checkbox input[type=checkbox]+label{position:relative;padding-left:31px;line-height:23px;cursor: pointer;}
.custom-checkbox input[type=checkbox]+label:after,.custom-checkbox input[type=checkbox]+label:before{content:"";position:absolute;top:0;left:0;width:18px;height:18px;border:1px solid #ddd;border-radius:4px}
.custom-checkbox input[type=checkbox]+label:after{background-image:url(../../../assets/images/check-ic.png);background-repeat:no-repeat;background-position:center center;transform:scale(0);transition:all .3s ease-in-out;background-size:14px;border-color: transparent;}
.custom-checkbox input[type=checkbox]:checked+label:after{transform:scale(1)}

.bottom-links{padding-top:40px;}
.bottom-links ul{margin:0}
.bottom-links ul li{list-style:none;display:inline-block;vertical-align:middle;color:#1073c6;padding-right:27px}
.bottom-links ul li:last-child{padding-right:0}
.bottom-links ul li a {display:inline-block;vertical-align:top;line-height:1}
.bottom-links button{font-size:16px;background:0 0;padding:0;line-height:1;color:#1073c6;text-transform:none;font-weight:400;text-decoration:underline;min-width:1px}
.bottom-links button:hover,.bottom-links button:focus { color: #646464; }

.right-column{width:50%;background:#0a5c7f;background:-moz-linear-gradient(180deg,#0a5c7f 0,#1588ba 100%);background:-webkit-linear-gradient(180deg,#0a5c7f 0,#1588ba 100%);background:linear-gradient(180deg,#0a5c7f 0,#1588ba 100%);color:#fff;padding:90px 20px 40px;display:flex;flex-wrap:wrap;align-items:flex-start}
.col-head{text-align:center;padding:0 40px}
.col-head h2{text-transform:uppercase;font-size: 40px;}
.col-head p{font-size:24px;padding:0 32px;line-height:1.55}

.image-slider{position:relative;width:100%;margin-top:auto}
.image-slider .slick-dots{bottom:0}
.image-item{padding:10px 2px 100px}
.image-item img{display:block;margin:0 auto}

.error-msg { display: block; color: #DC3545; position: absolute; top: 50%; right: 0; transform: translateY(-50%);    padding-right: 50px; }
.error-msg:after {content: "";position: absolute;top:0;right: 20px;background-image: url('../../../assets/images/alert-ic.svg');width: 20px;height: 20px;background-size: 20px;background-repeat: no-repeat;}


@media (min-width: 1501px) and (max-height:800px) {
    .login_page {padding: 30px 50px;}
    .col-head {padding: 0 20px;}
}

@media (max-width: 1500px) {
    .login_page { padding: 40px; }
    .login_page .main { min-height: calc(100vh - 80px); }
    .left-column { padding: 40px; }
    .main { width: 100%; max-width: 1240px; margin: 0 auto; } 

    .right-column { padding: 60px 20px 40px; }
    .col-head { padding: 0; }
    .col-head p {padding: 0 35px;font-size: 20px;}
    
    .col-head h2 { margin-bottom: 12px; font-size: 36px} .login_page { padding: 40px; } 
    .image-item { padding: 0px 32px 100px; }
}
@media (max-width: 1440px) {

}

@media (max-width: 1199px) {
    .login_page { padding: 50px; } 
    .login_page .main { min-height: calc(100vh - 100px); }
    .left-column { padding: 40px 40px; }
    .left-column h1 { margin-bottom: 20px; }
    .logo { margin-bottom: 40px; }    
    .logo a { max-width: 200px; }
    .user-field { background-size: 15px; background-position: center right 15px; }
    .pwd-field { background-size: 13px; background-position: center right 15px; }
    .bottom-links { padding-top: 30px; margin-left: 0; }

    .right-column { padding: 40px 30px; }
    .col-head p { font-size: 20px; padding: 0; }
    .image-item { padding: 10px 20px 80px; }

    .error-msg {font-size: 14px;padding-right: 35px;}   
    .error-msg:after {background-size: 15px;width: 15px;height: 15px;right: 15px;}
    .col-head h2 {font-size: 34px}
}
@media (min-width: 992px) and (max-height: 810px) {
    .logo { margin-bottom: 30px; }
    .left-column h1 { margin-bottom: 15px; }
    .right-column { padding: 30px 20px 30px; }
    .image-item { padding-bottom: 60px; }
    .bottom-links { padding-top: 30px; }
    .logo a { max-width: 180px; }
    .form-field { margin-bottom: 20px; }
    .login_page input[type=text] { padding: 17px 20px; } 
    .login_page input[type=submit] { padding: 24px 25px 22px; }
    login_page h1, .h1 { font-size: 38px; }
    .left-column { padding: 30px; }
}
@media (max-width: 991px) {
    .login_page { padding: 0; background-image: none !important; }
    .login_page .main {min-height: 100vh;} 
    .inner-screen { box-shadow: none; min-height: 100vh; } 
    .left-column { display: flex; flex-direction: column; padding: 80px 40px; }
    .left-column h1 { margin-bottom: 40px; }
    .error input::-moz-placeholder{opacity:0!important}
    .error input:-moz-placeholder{opacity:0!important}
    .error input::-webkit-input-placeholder{opacity:0!important}
    .error input:-ms-input-placeholder{opacity:0!important}
    .middle-content { margin-top: auto; margin-bottom: auto; padding-bottom: 20px; }
    
    .right-column { padding: 86px 30px 75px; } 
    .col-head { text-align: left; }
    .col-head h2 { padding-right: 17px; margin-bottom: 17px; }
    .col-head h2 {font-size: 32px}
    .col-head p { font-size: 16px; }

    .right-column {align-items: inherit;}    
    .image-slider {margin-top: 0;}
    .image-slider .slick-slider {height: 100%;}
  
}
@media (max-width: 991px) and (max-height:  740px) {
    .left-column { padding: 30px; }
    .right-column { padding: 30px 30px 40px; }
    .left-column h1 { margin-bottom: 20px; }
    .logo { margin-bottom: 30px; }
    .middle-content { padding-bottom: 0; }
    .form-field { margin-bottom: 20px; }
}

@media (max-width: 767px) {
    .login_page .main { min-height: 1px; }
    .inner-screen { display: block; min-height: 1px; }
    .left-column { width: 100%; padding: 30px 30px; display: block; }
    .left-column h1 { margin-bottom: 23px; }
    .logo { margin-bottom: 24px; }
    .logo a { max-width: 155px; }
    .middle-content { margin: 0; padding-bottom: 0; }
    .login_page .form-field.custom-checkbox { margin-bottom: 16px; }
    .custom-checkbox input[type=checkbox]+label { padding-left: 25px; line-height: 22px; }
    .custom-checkbox input[type=checkbox]+label:after, .custom-checkbox input[type=checkbox]+label:before { width: 16px; height: 16px; }
    .custom-checkbox input[type=checkbox]+label:after {background-size: 12px;;}
    .user-field { background-size: 13px; }
    .bottom-links { padding-top: 20px; margin-left: 8px; }
    .bottom-links button { font-size: 14px; }
    .bottom-links ul li { padding-right: 16px; }

    .right-column { width: 100%; display: block; padding: 31px 30px 15px; }    
    .col-head h2 { padding-right: 0; margin-bottom: 14px; }
    .image-item { padding: 0 40px 30px; }
    .image-slider .slick-slider { height: auto; }
    .col-head h2 {font-size: 20px}
}