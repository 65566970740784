.tab-listing ul {
	margin: 0;
}

.tab-listing ul li {
	list-style: none;
	display: inline-block;
	vertical-align: middle;
	color: #646464;
	font-weight: 700;
	font-size: 24px;
	font-family: "Roboto Condensed", sans-serif;
	text-transform: uppercase;
	padding-right: 52px;
}

.tab-content {
    padding: 0px 0 0;
}

.tab-content .form-main {
	margin-top: 0;
}

.tab-listing {
	padding: 22px 0 0px 0;

}

.tab-listing ul li a {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	line-height: 1;
	padding-bottom: 15px;
	padding-right: 12px;
}

.tab-listing ul li a:after {
	content: "";
	position: absolute;
	bottom: 0px;
	left: 0;
	height: 3px;
	background: #1073c6;
	width: 0;
	transition: all 0.5s ease-in-out;
}
.tab-listing ul li a:hover,
.tab-listing ul li a:focus,
.tab-listing ul .active {
	color: #1073c6;
}

.tab-listing ul .active:after,
.tab-listing ul li a:hover:after,
.tab-listing ul li a:focus:after {
	width: 100%;
}

.tab-content .field-left {
	width: 23%;
}

.tab-content .field-right {
	width: 77%;
}

.tab-content .form-main form {
	max-width: 843px;
}
.table-link a {
    color: #1073C6;
    text-decoration: underline;
}

.table-main-wrap {
    text-align: right;
}

.tab-listing + .table-main-wrap { 
    margin: 0;
}

.table-link {
    padding-bottom: 20px;
}
.table-main-wrap .MuiTableRow-root .MuiTableCell-root:first-child {
    min-width: 80px;
}

.table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 263px;
}

.table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 185px;
}
.MuiTableRow-root th.MuiTableCell-root {
    font-weight: 700;
}

.data-popup-box {
    min-width: 1120px;
    max-width: 1120px;
    margin: 160px 0;
}

.data-popup-box .box-heading {
    padding: 38px 15px;
}

.data-popup-box .box-heading .close-btn img {
    padding: 0;
    width: 20px;
}

.data-popup-box .close-btn {
    top: 30px;
    right: 24px;
}
.data-popup-box .box-content form { 
    max-width: 845px;
}

.data-popup-box .box-content {
    padding: 50px 40px;
}

.data-popup-box .box-content form .field-right {
    width: 77%;
}

.data-popup-box .box-content form .field-left {
    width: 23%;
}

.data-popup-box .form-submit {
    justify-content: center;
}
.table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
    min-width: 133px;
}

.table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
    min-width: 135px;
}
.MuiTableRow-root th.MuiTableCell-root {
    border-bottom-color: transparent;
    border-bottom-width: 0;
}

.tab-listing .slick-next {
	position: absolute;
    right: 0;
    top: -15px;
    bottom: 0;
	height: auto;
	margin: 0;
	transform: none
}
.tab-listing .slick-next:before {
	display: none;
}
.tab-listing .slick-next:after {
	content: "";	
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	background-image: url('../../../assets/images/right-arrow.svg');
	background-repeat: no-repeat;
	background-position: center right 30px;
	width: 80px;
	background-color: #f5f7fa;
	background-size: 7px;
}
.tab-listing .slick-next.slick-disabled {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}
.tab-listing .slick-prev {
	display: none !important;
}

.tab-listing .slick-slide {
	/* width: auto !important; */
	padding-right: 52px;
}
.tab-listing .slick-track {
    margin-left: 0;
}
@media (max-width: 1500px) {
	.tab-listing ul li {
		font-size: 20px;
	}

	.form-main {
		padding: 45px 40px;
	}
	.tab-listing .slick-slide {
		padding-right: 30px;
	}
}
@media (max-width: 1199px) {
	.data-popup-box {min-width: 800px;max-width: 800px;margin: 60px 0;}
	.table-main-wrap .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 190px;
	}
	.table-main-wrap .MuiTableRow-root .MuiTableCell-root {
		min-width: 130px;
	}
	.tab-listing .slick-slide {
		padding-right: 5px;
	}
}
@media (max-width: 991px) {
	.tab-listing ul li {
		font-size: 24px;
		padding-right: 42px;
	}
	.tab-listing .slick-slide {
		padding-right: 42px;
	}
	.tab-listing {
		padding: 11px 0 0;
	}
	.tab-listing {
		padding-right: 50px;
		position: relative;
	}
	.tab-arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}
/* 	
	.tab-arrow:after {
		content: "";	
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		background-image: url('../../../assets/images/right-arrow.svg');
		background-repeat: no-repeat;
		background-position: center right 30px;
		width: 80px;
		background-color: #f5f7fa;
		background-size: 7px;
	} */
	
	.tab-content {
		padding: 0;
	}
	
	.tab-listing ul li a:after {
		bottom: 0;
	}
	
	.tab-content .form-main form {
		max-width: 477px;
	}
	
	.tab-content .field-left {
		width: 34%;
	}
	
	.tab-content .field-right {
		width: 66%;
	}
	
	.tab-content .form-main {
		padding-bottom: 140px;
	}
	.data-popup-box {
		min-width: 650px;
		max-width: 650px;
		margin: 100px 0;
	}
	
	.data-popup-box .box-heading {
		padding: 30px 15px;
	}
	
	.data-popup-box .close-btn {
		top: 20px;
		right: 10px;
	}
	
	.data-popup-box .box-heading .close-btn img {
		width: 15px;
	}
	
	.data-popup-box .box-content form {
		width: 100%;
		max-width: 510px;
	}
	
	.data-popup-box .box-content form .field-right {
		width: 60%;
	}
	
	.data-popup-box .box-content form .field-left {
		width: 40%;
	}
	
	.data-popup-box .box-content {
		padding: 50px 40px 60px;
	}
}
@media (max-width: 767px){
	.tab-listing ul li {
		font-size: 20px;
	}
	.data-popup-box {
		min-width: 345px;
		max-width: 345px;
		margin: 80px 0;
	}
	.data-popup-box .box-content form .field-left {
		width: 100%;
		padding: 0;
	}
	
	.data-popup-box .box-content {
		padding: 31px 15px 15px;
	}
	
	.data-popup-box .box-content form .field-right {
		width: 100%;
	}
	
	.data-popup-box .form-submit .button-field input {
		padding-left: 13px;
		padding-right: 13px;
	}

	.data-popup-box .box-heading .close-btn img {
		width: 10px;
	}
	
	.data-popup-box .close-btn {
		top: 10px;
		right: 10px;
		width: 10px;
	}
	.tab-listing .slick-next {
		top: -16px;
	}
}
@media (max-width: 639px){
	.tab-content .form-main {
		padding-bottom: 6px;
	}

	.tab-listing ul li {
		font-size: 16px;
		padding-right: 30px;
	}
	.tab-listing .slick-slide {
		padding-right: 10px;
	}
	
	.tab-content .field-left {
		width: 100%;
		padding: 0;
	}
	
	.tab-content .field-right {
		width: 100%;
	}
	
	.tab-listing ul li:last-child {
		padding-right: 0;
	}
	
	.tab-listing {
		padding: 8px 0 0px;
	}
	.ecp_resource .tab-listing ul li a {
		padding-bottom: 18px;
	}
	
	.tab-listing ul li a:after {
		bottom: 0px;
	}
	
	.tab-listing ul li a {
		padding-right: 2px;
		padding-bottom: 11px;
	}
	
	.tab-content {
		padding: 0px 0 0;
	}
	
	.tab-content .form-main form {
		max-width: 315px;
	}
	
	.tab-content .form-main .form_field {
		padding-right: 0;
	}
	.tab-listing + .table-main-wrap {
		margin-top: 7px;
	}
	.table-link {
		padding-bottom: 14px;
	}
	
	.table-main-wrap .MuiTableRow-root .MuiTableCell-root:first-child {
		min-width: 60px;
	}
	
	.table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
		min-width: 96px;
	}
	
	.table-main-wrap.users-class .MuiTableRow-root .MuiTableCell-root:nth-child(3) {
		min-width: 95px;
	}
}
