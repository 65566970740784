.two-col-sec {
    background: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 31px 40px 60px;
    margin-top: 40px;
    box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
}
.two-col-sec p {
    font-size: 24px;
    letter-spacing: 0;
    font-family: Roboto,sans-serif;
    line-height: 2;
    margin-bottom: 47px;
}

.two-col-sec p:last-child {
    margin-bottom: 0;
}
.sec-left {
    width: 50%;
    padding-right: 10px;
}

.sec-right {
    width: 50%;
    padding: 100px 0 0 50px;
}
.sec-right img {
    display: block;
    margin: 0 auto;
}

@media (max-width: 1500px) {
    .two-col-sec p {
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 1.8;
    }
    
    .sec-left {
        width: 55%;
    }
    
    .sec-right {
        width: 45%;
        padding-left: 15px;
        padding-top: 60px;
    }
 
}

@media (max-width: 1199px) {
    .sec-left {
        width: 55%;
    }
    
    .sec-right {
        width: 45%;
        padding-left: 15px;
        padding-top: 60px;
    }
    
   
    
 
    
    .two-col-sec {
        margin-top: 20px;
        padding: 30px;
    }
    
    .two-col-sec p {
        font-size: 16px;
        margin-bottom: 15px;
    }
    
  
}

@media (max-width: 991px) {
    .sec-left {
        width: 100%;
        padding: 0;
    }
    
    .sec-right {
        width: 100%;
        order: -1;
        padding: 0;
        margin-bottom: 47px;
    }
    
   
    .two-col-sec {
        margin-top: 27px;
        padding: 40px 30px 23px;
    }
    
    .sec-right img {
        max-width: 420px;
    }
    
    .two-col-sec p {
        line-height: 2;
        margin-bottom: 32px;
    }
}

@media (max-width: 767px) {
    
    .sec-right img {
        max-width: 250px;
    }
    
   
    
 
    
    .two-col-sec {
        margin-top: 17px;
        padding: 20px 20px 14px;
    }
    
    .two-col-sec p {
        font-size: 14px;
        margin-bottom: 28px;
    }
    
    .sec-right {
        margin-bottom: 14px;
    }
}