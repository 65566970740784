.two-col-sec h3 {font-family: Roboto,sans-serif;font-weight: 400;}

.two-col-sec p + h3 {
    margin-top: 56px;
    margin-bottom: 21px;
}

.two-col-sec li {
    font-size: 24px;
}

.two-col-sec ul {
    margin: 0 0 62px;
}

.two-col-sec ul li {
    list-style: none;
    display: block;
    position: relative;
    padding-left: 26px;
    line-height: 1.2;
    margin-bottom: 20px;
}

.two-col-sec ul li:after {content: "";position: absolute;top: 10px;left: 1px;width: 5px;height: 5px;background: #4B4C4C;border-radius: 100%;}

.detail-welcome {
    margin-top: 28px;
    padding-bottom: 12px;
    margin-bottom: 40px;
}

.detail-welcome .sec-right {
    padding-top: 9px;
}

.full-field label {
    display: block;
    margin-bottom: 15px;
}

.full-field {
    margin-bottom: 29px;
}

.full-field p small {
    font-size: 14px;
    color: rgba(85, 85, 85, 0.5);
}

.full-field p {
    line-height: 1;
    margin-top: 22px;
}

.blue-link {
    text-align: center;
    margin-top: 40px;
}

.blue-link a {
    color: #1073C6;
    font-size: 24px;
    text-decoration: underline;
    display: inline-block;
    vertical-align: middle;
}
.blue-link a:hover,.blue-link a:focus{
    text-decoration: none;
}
.full-field.budget_detail_field {
    max-width: 650px;
}

@media (max-width: 1500px) {
    .two-col-sec ul li,.blue-link a,.two-col-sec h3 {
        font-size: 20px;
    }
    
    
    .two-col-sec p + h3 {
        margin-top: 40px;
    }
    
    .blue-link {
        margin-top: 30px;
    }   
}

@media (max-width: 1199px) {
    .two-col-sec ul li, .blue-link a, .two-col-sec h3 {
        font-size: 16px;
    }
    
    .two-col-sec ul li {
        margin-bottom: 15px;
    }
    
    .two-col-sec p + h3 {
        margin-top: 30px;
        margin-bottom: 15px;
    }
    
    .two-col-sec ul {
        margin-bottom: 40px;
    }
    
    .blue-link {
        margin-top: 20px;
    }
    
    .full-field p small {
        font-size: 12px;
    }
    
    .full-field p {
        margin-top: 17px;
    }
    
    .button-field.next-step-btn input {
        font-size: 16px;
        line-height: 18px;
    }
}

@media (max-width: 991px) {
    .detail-welcome .sec-right {
        order: inherit;
        padding-top: 12px;
        margin-bottom: 0;
    }
    
    .detail-welcome {
        padding-top: 24px;
        padding-bottom: 29px;
        margin-bottom: 30px;
    }
    
    .two-col-sec p + h3 {
        margin-top: 38px;
        margin-bottom: 12px;
    }
    
    .two-col-sec ul li {
        padding-left: 17px;
        margin-bottom: 13px;
    }
    
    .two-col-sec ul li:after {
        top: 7px;
        width: 3px;
        height: 3px;
    }
    
    .detail-welcome .full-field {
        display: inline-block;
        vertical-align: top;
        width: 46%;
        margin-right: 2.5%;
    }
    
    .full-field p small {
        font-size: 14px;
    }
    
    .full-field p {
        margin-left: -105%;
        margin-top: 13px;
        width: calc(100vw - 100px);
    }
    
    .blue-link a {
        font-size: 24px;
    }
    
    .blue-link {
        margin-top: 14px;
    }
    
    .button-field.next-step-btn {
        min-width: 228px;
    }
    
    .button-field.next-step-btn input {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 767px) {
    .two-col-sec ul li, .blue-link a, .two-col-sec h3 {
        font-size: 14px;
    }
    
    .detail-welcome {
        padding-top: 15px;
        margin-top: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .two-col-sec p + h3 {
        margin-top: 33px;
        margin-bottom: 7px;
    }
    
    .two-col-sec ul li {
        padding-left: 15px;
        margin-bottom: 6px;
        line-height: 1.7;
    }
    
    .detail-welcome .full-field {
        display: block;
        width: 100%;
        margin: 0 0 19px;
    }
    
    .two-col-sec ul {
        margin-bottom: 38px;
    }
    
    .full-field label {
        margin-bottom: 9px;
    }
    
    .full-field p {
        margin-left: 0;
        width: 100%;
        margin-top: 15px;
    }
    
    .full-field p small {
        font-size: 12px;
    }
    
    .detail-welcome .sec-right {
        padding-top: 22px;
    }
    
    .detail-welcome .full-field:last-child {
        margin-bottom: 0;
    }
    
    .detail-welcome .sec-right img {
        margin-left: 0;
    }
    
    .blue-link {
        text-align: left;
        margin-top: 11px;
    }
    .button-field.next-step-btn input {
        font-size: 12px;
        line-height: 14px;
        padding-top: 9px;
        padding-bottom: 9px;
    }
    
    .button-field.next-step-btn {
        min-width: 184px;
    }
}