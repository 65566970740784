.three-block-sec {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 60px;
    padding-right: 40px;
}

.block-col {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(10, 10, 10, 0.06);
    border-radius: 5px;
    width: 31.3%;
    margin-right: 3.05%;
    margin-bottom: 3.05%;
    text-align: center;
}

.block-col:nth-child(3n) {
    margin-right: 0;
}

.hover_link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 49px 15px 46px;
}

.hover_link:hover,.hover_link:focus {
    background: #01AFB0;
    color: #fff;
}

.block-col p {
    margin: 0 0 7px;
    font-size: 18px;
    font-family: Roboto,sans-serif;
    color: rgba(100, 100, 100, 0.6);
    letter-spacing: 0.5px;
    transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
}

.hover_link:hover p,.hover_link:focus p {
    color: rgba(255, 255, 255, 0.7);
}

.block-col img {
    display: block;
    margin: 0 auto 41px;
}

.block-col h2 {
    margin: 0;
    font-size: 36px;
}

@media (max-width: 1500px){
    .three-block-sec {
        padding-right: 0;
    }
    .hover_link {
        padding: 30px 15px;
    }
    
    .block-col h2 {
        font-size: 32px;
    }
}

@media (max-width: 1199px){
    .hover_link {
        padding: 30px 15px;
    }
    
    .block-col h2 {
        font-size: 32px;
    }
    
    .three-block-sec {
        padding-right: 0;
        margin-top: 30px;
    }
    
    .block-col {
        width: 48.47%;
    }
    .block-col:nth-child(3n) {
        margin-right: 3.05%;
    }
    
    .block-col:nth-child(2n) {
        margin-right: 0;
    }
}

@media (max-width: 991px){
    .three-block-sec {
        margin-top: 27px;
    }
    .block-col p {
        font-size: 16px;
    }
    
    .block-col h2 {
        font-size: 24px;
    }
    
    .block-col img {
        max-width: 80px;
    }
}

@media (max-width: 767px){
    .block-col {
        width: 100%;
        margin: 0 0 15px;
    }
    
    .block-col:nth-child(3n) {
        margin-right: 0;
    }
    
    .block-col p {
        font-size: 14px;
        letter-spacing: 0;
        margin-bottom: 10px;
    }
    
    .block-col h2 {
        font-size: 18px;
    }
    
    .block-col img {
        max-width: 70px;
        margin-bottom: 22px;
    }
    
    .three-block-sec {
        margin-top: 20px;
    }
}