.site-footer {
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px 40px;
    background: rgba(255, 255, 255, 0.5);
}

.footer-left {
    width: 50%;
}

.footer-right {
    width: 50%;
    text-align: right;
}

.footer-right ul {
    margin: 0;
}

.footer-right ul li {
    list-style: none;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-family: 'Roboto Condensed',sans-serif;
    padding-right: 35px;
    position: relative;
}

.footer-right ul li:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    height: 15px;
    width: 2px;
    background: #DDDDDD;
    right: 17px;
    margin: auto;
}

.footer-left p {
    margin: 0;
}

.footer-left p a {
    color: #177BC9;
}

.footer-right ul li:last-child {
    padding-right: 0;
}



@media (max-width: 1199px) {
    .footer-right ul li {
        padding-right: 20px;
        font-size: 14px;
    }
    
    .footer-right ul li:after {
        right: 10px;
        height: 13px;
    }
    
    .site-footer {
        padding: 12px 20px;
    }
    
    .footer-left p {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    
.footer-left {
    width: 100%;
    margin-bottom: 8px;
}

.footer-right {
    width: 100%;
    text-align: left;
}

.site-footer {
    padding: 14px 15px;
}

.footer-left p {
    font-size: 12px;
}

.footer-right ul li {
    font-size: 12px;
}
}